<template>
  <div class="top-ten-list">
    <div class="product-item" v-for="it in topList" :key="it">
      <div class="product-item-title">{{it}}</div>
      <div>
        <img src="/imgs/product/product-box1/product-box1-1.png" />
        <div class="product-item-info">
          <div class="color-b">产品已上链确权</div>
          <div>案例:<span class="color-r">35</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "topTen",
  props: {
    topList: {
      type: Array,
      default: ()=> {
        return []
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/base.scss";
@import "../../assets/scss/config.scss";
.top-ten-list {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  .product-item {
    width: 208px;
    height: 76px;
    margin: 5px 0;
    border-radius: 4px;
    padding: 16px 12px;
    background-color: #EBE6E6;
    .product-item-title {
      font-size: 14px;
      font-weight: 600;
    }
    img{
      width: 60px;
      border-radius: 4px;
      margin-top: 12px;
      margin-right: 15px;
      vertical-align: middle;
    }
    .product-item-info {
      display: inline-block;
      vertical-align: middle;
      font-size: 10px;
      >div {
        line-height: 20px;
      }
      .info-b {
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 10px;
      }
    }
  }
  .product-item:nth-child(2) {
    background-color: #E6E9EB;
  }
  .product-item:nth-child(4) {
    background-color: #E6E9EB;
  }
  .product-item:nth-child(7) {
    background-color: #E6E9EB;
  }
  .product-item:nth-child(9) {
    background-color: #E6E9EB;
  }
}
</style>
