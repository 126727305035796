<!--精选产品模块-->
<template>
  <div class="recommend-data">
    <div class="left-data">
      <div class="left-data-title">精选各种场景优秀产品和方案</div>
      <div class="left-data-item" v-for="item in leftList" :key="item.id">
        <img src="/imgs/index/recommend-l-1.png"/>
        <span>{{item.name}}</span>
        <span class="icon"> > </span>
      </div>
    </div>

    <div class="right-data">
      <div class="right-data-item" v-for="item in rightList" :key="item.id">
        <div class="title">{{item.title}}</div>
        <div class="item-info">
            <span>
              <img src="/imgs/key.png" />
              <span class="color-b">产品已上链确权</span>
            </span>
          <span>被下载<span class="color-b">88</span></span>
          <span><span class="color-r">100</span>/年</span>
        </div>
        <div class="item-info">
          <span>发布于2022年4月2日</span>
          <span>人气值<span class="color-b">1221</span></span>
          <span>被收藏<span class="color-r">767</span></span>
        </div>
        <div>适用于<span>Windows/Linux</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recommend",
  data() {
    return{
      leftList: [
        {id: 1,name: '开源企业', path: '',img: '',},
        {id: 2,name: '通用算法', path: '',img: '',},
        {id: 3,name: '物联网IO', path: '',img: '',},

      ],
      rightList: [
        {id: 1, title: '开源企业发行版'},
        {id: 2, title: '开源企业发行版'},
        {id: 3, title: '开源企业发行版'},
        {id: 4, title: '开源企业发行版'},
        {id: 5, title: '开源企业发行版'},
        {id: 6, title: '开源企业发行版'},
      ],
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/base.scss";
@import "../../assets/scss/config.scss";
.recommend-data {
  display: flex;
  .left-data {
    width: 200px;
    height: 190px;
    background-color: rgba(156, 201, 247, .4);
    border-radius: 4px;
    padding: 16px;
    font-weight: 600;
    margin-right: 20px;
    .left-data-title {
      font-size: 14px;
      color: #101010;
    }
    .left-data-item {
        margin: 15px 0;
      cursor: pointer;
      img {
        width: 32px;
        vertical-align: middle;
        margin-right: 10px;
      }
      .icon {
        float: right;
        margin-top: 8px;
      }
    }
  }
  .right-data {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 1000px;
    color: #ADABAB;
    .right-data-item {
      width: 290px;
      background-color: rgba(219, 247, 228, .4);
      border-radius: 4px;
      margin-bottom: 16px;
      padding: 12px;
      >div {
        line-height: 20px;
      }
      .title {
        font-size: 14px;
        color: #101010;
        font-weight: 600;
      }
      .item-info {
        display: flex;
        justify-content: space-between;
      }

      img {
        width: 12px;
      }
    }
  }
  .color-r {
    color: red;
  }
  .color-b {
    color: #2299F5;
  }
}
</style>
