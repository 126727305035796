<template>
  <div class="product-box1">
    <div class="product-item" v-for="item in indexLineFourData" :key="item.title">
      <div class="product-item-title">{{item.title}}</div>
      <img :src="item.img" />
    </div>
  </div>
</template>

<script>
export default {
  name: "fourLine",
  props:{
    indexLineFourData:{
      type: Array,
      default: () => {
        return []
      }
    }
  },


}
</script>

<style scoped lang="scss">
@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/base.scss";
@import "../../assets/scss/config.scss";
.product-box1 {
  display: flex;
  justify-content: space-between;
  .product-item {
    padding: 12px;
    display: inline-block;
    width: 270px;
    height: 270px;
    border-radius: 4px;
    text-align: center;
    .product-item-title {
      text-align: left;
      padding-top: 20px;
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 600;
    }
    img {
      width: 100%;
    }
  }
  .product-item:nth-child(odd) {
    background-color: #EBE6E6;
  }
  .product-item:nth-child(even) {
    background-color: #E6E9EB;
  }
}
</style>
