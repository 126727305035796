<template>
  <div class="product-box2-list">
    <div class="product-item" v-for="it in indexLineEightData" :key="it">
      <div class="product-item-title">{{it}}</div>
      <div>
        <img src="/imgs/product/product-box1/product-box1-1.png" />
        <div class="product-item-info">
          <div>
            <span class="info-b color-b">产品已上链确权</span>
            <span>人气值:<span class="color-b">35</span></span>
          </div>
          <div>
            <span class="mr-5"><span class="color-r">10,000</span>/次</span>
            <span>被申请使用<span class="color-b">51</span></span>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "eightLine",
  props: {
    indexLineEightData: {
      type: Array,
      default: ()=> {
        return []
      }
    }
  },

}
</script>

<style scoped lang="scss">
@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/base.scss";
@import "../../assets/scss/config.scss";
.product-box2-list {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  .product-item {
    width: 270px;
    height: 86px;
    margin: 5px 0;
    border-radius: 4px;
    padding: 20px 12px;
    .product-item-title {
      font-size: 14px;
      font-weight: 600;
    }
    img{
      width: 80px;
      border-radius: 4px;
      margin-top: 12px;
      margin-right: 15px;
      vertical-align: middle;
    }
    .product-item-info {
      display: inline-block;
      vertical-align: middle;
      font-size: 10px;
      width: calc(100% - 96px);
      >div {
        display: flex;
        justify-content: space-between;
      }
      .info-b {
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 10px;
      }
    }
  }
  .product-item:nth-child(odd) {
    background-color: #EBE6E6;
  }
  .product-item:nth-child(even) {
    background-color: #E6E9EB;
  }
}
</style>
