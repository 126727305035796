/*首页（基于home.vue）*/
<template>
  <div class="index">
    <page-nav :pageList="pageList"></page-nav>
    <div class="container">
      <!-- 轮播图菜单布局 -->
      <div class="swiper_box">
        <div class="banner_menu">
          <ul class="nav_wrap">
            <li
              class="nav_list"
              v-for="(item, index) in categorylist"
              :key="index"
            >
              <a href="javascript:;">{{ item }}</a>
              <div class="children">
                <ul v-for="(item, i) in menulist" :key="i">
                  <li v-for="(sub, j) in item" :key="j">
                    <a @click="$router.push(sub ? '/product/' + sub.id : '')">
<!--                      <img-->
<!--                        v-lazy="sub ? sub.img : '/imgs/item-box-2.png'"-->
<!--                        alt=""-->
<!--                      />-->
                      {{ sub ? sub.name : "码力" }}
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <!-- banner -->
        <!-- banner -->
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(item, index) in slideList" :key="index"
          ><a @click="$router.push('/product/' + item.id)"
          ><img v-lazy="item.img" alt="" /></a
          ></swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
      <!-- 广告位（坑） -->
      <div class="adv_b">
        <a
          @click="$router.push(item.id)"
          v-for="(item, index) in advlist"
          :key="index"
        >
          <img v-lazy="item.img" alt="" />
        </a>
      </div>
    </div>


    <!-- 首页商品列表 -->
    <div class="product_box">
      <div class="box_container">
        <div class="products_list">
          <four-line :indexLineFourData="productBox1"></four-line>
          <div>
            <list-title title="精选产品"></list-title>
            <recommend></recommend>
          </div>

          <four-line :indexLineFourData="productBox2"></four-line>
          <!--    可申请试用      -->
          <div>
            <list-title title="可申请试用"></list-title>
            <eight-line :indexLineEightData="tryList"></eight-line>
          </div>
          <div>
            <list-title title="用户案例TOP10"></list-title>
            <top-ten :topList="topList"></top-ten>
          </div>
          <div>
            <list-title title="有在线客服"></list-title>
            <eight-line :indexLineEightData="customerList"></eight-line>
          </div>
          <div>
            <list-title title="热门场景"></list-title>
            <four-line :indexLineFourData="hotList"></four-line>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import PageNav from "../components/pageNav";
import Recommend from "../components/indexData/recommend";
import FourLine from "../components/indexData/fourLine";
import EightLine from "../components/indexData/eightLine"
import TopTen from "../components/indexData/topTen"
import ListTitle from "../components/indexData/listTitle";
export default {
  components: {ListTitle, TopTen, EightLine, FourLine, Recommend, PageNav, Swiper, SwiperSlide},
  name: "index",
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 3000, //1秒切换一次
          disableOnInteraction: true,
        },
        loop: true, // 循环模式选项
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
        },
        // effect: "cube",
      },
      abvdatalist: [
        { id: 33, img: "/imgs/ads/ads-1.png" },
        { id: 48, img: "/imgs/ads/ads-2.jpg" },
        { id: 45, img: "/imgs/ads/ads-3.png" },
        { id: 47, img: "/imgs/ads/ads-4.jpg" },
      ],
      // 轮播数据
      slideList: [
        { id: "42", img: "/imgs/slider/slide-1.png" },
        { id: "45", img: "/imgs/slider/slide-2.png" },
        { id: "46", img: "/imgs/slider/slide-3.png" },
      ],
      // categorylist
      categorylist: [
        "企业管理软件",
        "智慧城市",
        "大数据",
        "人工智能",
        "云计算",
        "工业互联网",
        "信息安全",
        "书记模拟技术",
      ],
      // 轮播列表数据：
      menulist: [
        [
          {
            id: 30,
            // img: "/imgs/item-box-1.png",
            name: "医疗辅助",
          },
          { id: 31,
            // img: "/imgs/item-box-1.png",
            name: "综合运营" },
          { id: 32,
            // img: "/imgs/item-box-3.jpg",
            name: "临床科研" },
          { id: 33,
            // img: "/imgs/item-box-4.jpg",
            name: "智慧医疗" },
        ],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
      ],

      // 广告海报数据
      advlist: [
        {id: 33, img:"/imgs/product/product-box1/product-box1-1.png"},
        {id: 48, img:"/imgs/product/product-box1/product-box1-2.png"},
        {id: 45, img:"/imgs/product/product-box1/product-box1-3.png"},
        {id: 47, img:"/imgs/product/product-box1/product-box1-4.png"},
      ],
      // 随机产生的数组
      romdomarray: [],
      // 是否打开弹框
      isopenthemodel: false,

      productBox1:[
        {title: "制造企业数智化系统专区",img: "/imgs/product/product-box1/product-box1-1.png"},
        {title: "5G上云系统专区", img: "/imgs/product/product-box1/product-box1-2.png"},
        {title: "企业应用系统专区", img: "/imgs/product/product-box1/product-box1-3.png"},
        {title: "网站建设专区", img: "/imgs/product/product-box1/product-box1-4.png"}
      ],

      //
      productBox2:[
        {title: "远程云卓面系统", img: "/imgs/index/product-box1-1.png"},
        {title: "开源机器狗系统", img: "/imgs/index/product-box1-2.png"},
        {title: "Apollo自动驾驶开源", img: "/imgs/index/product-box1-3.png"},
        {title: "虚拟数字人系统", img: "/imgs/index/product-box1-4.png"},
      ],

      //热门场景
      hotList: [
        {title: "IOT智慧工厂", img: "/imgs/product/product-box1/product-box1-1.png"},
        {title: "5G智慧城市", img: "/imgs/product/product-box1/product-box1-2.png"},
        {title: "区块链智慧政务", img: "/imgs/product/product-box1/product-box1-3.png"},
        {title: "5G智慧工地", img: "/imgs/product/product-box1/product-box1-4.png"},
      ],
      // 可申请试用
      tryList:['响应式建站买断方式','网络安全堡垒机', '图像识别','自然语义解析','自动化部署生产环境','中小企业ERP系统','3D建模系统','智能客服机器人'],
      // top10
      topList:['远程办公系统','AI人脸识别','物联网PLMMISMES','安防监控系统','加密软件系统','网络信息与安全','数字模拟技术','图像处理技术系统','存储虚拟化系统','IOT定位系统'],

      // 有在线客服
      customerList:['云计算','多媒体','AR VR MR','视频会议','分布式PaaS','云桌面','智慧社区','语音识别'],
      // 导航
      pageList: [
        {name: '首页',path: 'index'},
        {name: '供需大厅',path: 'index'},
        {name: '开源企业',path: 'index'},
        {name: '通用算法',path: 'index'},
        {name: '移动版',path: 'index',children:[
            {title: '天天数链码力小程序', desc: '让天下没有难找的代码',img: '/imgs/ewm.png'},
            {title: '天天数链码力公众号', desc: '随时查找优秀代码',img: '/imgs/ewm.png'},
            {title: '天天数链码力订阅号', desc: '咨询动态',img: '/imgs/ewm.png'},
          ]},
      ],

    };
  },
  directives: {
    swiper: directive,
  },
  mounted() {
    this.getdata();
    // 坑点：如何放入v-for内让其每次循环时执行
    this.setRomdon();
  },
  methods: {
    /* 首页数据请求 */
    getdata() {
      // this.axios
      //   .get("/products", {
      //     params: {
      //       pageSize: 50,
      //     },
      //   })
      //   .then((res) => {
      //     this.indexproductsinfo[0].data = res.list.slice(4, 12);
      //     this.indexproductsinfo[1].data = res.list.slice(12, 20);
      //     this.indexproductsinfo[2].data = res.list.slice(4, 12);
      //     this.indexproductsinfo[3].data = res.list.slice(12, 20);
      //     this.indexproductsinfo[4].data = res.list.slice(4, 12);
      //   });
    },
    setRomdon() {
      // 每次进来清空数组
      this.romdomarray.splice(0, this.romdomarray.length);
      for (let index = 0; index < 4; index++) {
        var a = Math.floor(Math.random() * (7 - 0 + 1)) + 0; //含最大值，含最小值
        this.romdomarray.push(a);
      }
    },
    setnewproduct(inedx) {
      for (let index = 0; index < this.romdomarray.length; index++) {
        const element = this.romdomarray[index];
        if (inedx == element) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
<style lang="scss">
</style>
