<template>
  <div class="product-box-title">
    <div class="title">{{title}}</div>
    <div class="more" @click="checkMore">查看更多></div>
  </div>

</template>

<script>
export default {
  name: "listTitle",
  props: {
    title: {
      type: String,
      default: '',
    }
  },
  methods: {
    checkMore() {
      this.$message.info('暂无更多数据')
    },
  },
}
</script>

<style scoped lang="scss">

.product-box-title {
  width: 100%;
  margin: 30px 0 15px 0;
  display: flex;
  justify-content: space-between;
  .title {
    color: red;
    font-size: 16px;
    font-weight: 600;
  }
  .more {
    color: #101010;
    font-size: 14px;
    cursor: pointer;
  }
}

</style>
